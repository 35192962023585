<template>
  <!-- FORMULIR PERTANYAAN KUNCI KESEHATAN LINGKUNGAN -->
  <div class="bg_main full_h pb-5">
    <header-formulir
      v-bind:date="$date()"
      v-bind:title="'Formulir Pertanyaan Kunci Kesehatan Lingkungan'"
    />
    <div class="d-flex justify-center">
      <!-- <div class="side_toolbar">
        <div>
          <div class="item_toolbar" @click="setupForm(true)">
            <v-progress-circular
              v-if="loadTempo"
              indeterminate
              color="#00b4cc"
            ></v-progress-circular>
            <v-icon color="#00b4cc" v-else large
              >mdi-content-save-outline</v-icon
            >
          </div>
        </div>
      </div> -->
      <div style="margin-top: 50px; width: 85%" class="pb-5">
        <v-card
          class="rounded-max custom_card pa-5"
          v-if="air && limbah && sanitasi && higiene && lingkungan"
        >
          <v-card color="#CFEEF2" flat class="rounded-max mb-5">
            <div class="fill_header1 white--text py-6 px-5">
              <b>Air</b>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              1. Apa sumber air utama yang digunakan oleh Fasyankes ? (Sumber
              air yang paling banyak atau sering digunakan untuk kegiatan
              higiene dan sanitasi)
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.air[0].status == 1"
                  @click="dataConfirm.air[0].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.air[0].status == 0"
                  @click="dataConfirm.air[0].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>

            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                dense
                v-model="
                  air.Sumber_air_utama_yang_digunakan_oleh_fasyankes[0]
                    .dataPenyusun_desc
                "
              >
                <v-row>
                  <v-col cols="4">
                    <v-radio
                      label="Air perpipaan"
                      color="mainGreen"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Sumur bor/pompa"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Sumur gali terlindungi"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                    <v-radio
                      label="Penampungan air hujan"
                      color="mainGreen"
                      value="4"
                    ></v-radio>
                  </v-col>
                  <v-col cols="4">
                    <v-radio
                      label="Mata air terlindungi"
                      color="mainGreen"
                      value="5"
                    ></v-radio>
                    <v-radio
                      label="Penyediaan truk tangki"
                      color="mainGreen"
                      value="6"
                    ></v-radio>
                    <v-radio
                      label="Mata air tidak terlindungi"
                      color="mainGreen"
                      value="7"
                    ></v-radio>
                    <v-radio
                      label="Sumur gali tidak terlindungi"
                      color="mainGreen"
                      value="8"
                    ></v-radio>
                  </v-col>
                  <v-col cols="4">
                    <v-radio
                      label="Air permukaan (sungai/danau/kanal)"
                      color="mainGreen"
                      value="9"
                    ></v-radio>
                    <v-radio
                      label="Tidak ada sumber air"
                      color="mainGreen"
                      value="10"
                    ></v-radio>
                    <v-radio
                      label="Sumber lainnya"
                      color="mainGreen"
                      value="11"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              2. Dimana lokasi sumber air utama yang digunakan oleh Fasyankes ?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.air[1].status == 1"
                  @click="dataConfirm.air[1].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.air[1].status == 0"
                  @click="dataConfirm.air[1].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                v-model="
                  air.Sumber_air_utama_ada_di_fasilitas_pelayanan_kesehatan[0]
                    .dataPenyusun_desc
                "
                disabled
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio
                      label="Di dalam area Fasyankes"
                      color="mainGreen"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Di luar area Fasyankes dalam jarak kurang dari 500 meter"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Di luar area Fasyankes pada jarak lebih dari 500 meter"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              3. Apakah air dari sumber utama saat ini tersedia cukup dan
              memadai untuk seluruh aktivitas di Fasyankes ?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.air[2].status == 1"
                  @click="dataConfirm.air[2].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.air[2].status == 0"
                  @click="dataConfirm.air[2].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                dense
                v-model="
                  air.Air_dari_sumber_utama_saat_ini_tersedia[0]
                    .dataPenyusun_desc
                "
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-7" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              4. Apakah air minum di Fasyankes memenuhi standar sisa klorin atau
              tidak ada E. coli ? (Air minum adalah air siap minum, standar sisa
              klorin air dari sumber perpipaan pada kondisi normal adalah 0,2
              s.d. 0,5 mg/l atau E. Coli 0 per 100 ml)
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.air[3].status == 1"
                  @click="dataConfirm.air[3].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.air[3].status == 0"
                  @click="dataConfirm.air[3].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  air[
                    'Air_minum_memiliki_sisa_klorin_yang_sesuai_0,2_mg_L_atau_0,5_mg_L_dalam_keadaan_darurat__atau_E._coli_100_ml'
                  ][0].dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio
                      label="Ya, air minum memenuhi standar"
                      color="mainGreen"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Tidak, air minum belum memenuhi standar"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Air minum tidak diuji"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card color="#CFEEF2" flat class="rounded-max mb-5">
            <div class="fill_header2 white--text py-6 px-5">
              <b>Sanitasi</b>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              5. Berapa jumlah toilet yang dapat digunakan (tersedia, berfungsi,
              privasi) di Fasyankes ?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[0].status == 1"
                  @click="dataConfirm.sanitasi[0].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[0].status == 0"
                  @click="dataConfirm.sanitasi[0].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div class="mt-5" style="margin: 0 20px">
              <v-card
                class="d-flex align-center pl-2 px-5"
                outlined
                style="width: max-content"
              >
                <p class="ma-0 small_txt color_txt">Jumlah</p>
                <input
                  type="number"
                  v-model="
                    sanitasi
                      .Jumlah_toilet_yang_dapat_digunakan__tersedia_fungsional_pribadi__untuk_fasilitas_pelayanan_kesehatan[0]
                      .dataPenyusun_desc
                  "
                  class="pa-2 custom_input"
                />
              </v-card>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              6. Apa jenis kloset atau jamban yang paling umum digunakan di
              Fasyankes
            </h5>

              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[1].status == 1"
                  @click="dataConfirm.sanitasi[1].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[1].status == 0"
                  @click="dataConfirm.sanitasi[1].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  sanitasi.Jenis_toilet_jamban__pilih_salah_satu_paling_umum_[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col cols="8">
                    <v-radio
                      label="Kloset siram yang tersambung dengan Instalasi Pengolah Air Limbah (IPAL)"
                      color="mainGreen"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Kloset siram yang tersambung dengan tangki septik"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Kloset siram yang tersambung ke saluran terbuka"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                    <v-radio
                      label="Ada kloset tapi tidak berfungsi atau tidak tersedia kloset"
                      color="mainGreen"
                      value="4"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              7. Apakah toilet dipisah dengan jelas (terdapat label) untuk
              petugas dan pasien?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[2].status == 1"
                  @click="dataConfirm.sanitasi[2].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[2].status == 0"
                  @click="dataConfirm.sanitasi[2].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  sanitasi.Toilet_terpisah_untuk_staf_dan_pasien[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              8. Apakah tersedia toilet yang dipisah dengan jelas (terdapat
              label) untuk pria dan wanita atau terdapat privasi jika gender
              netral? (privasi pada toilet gender netral misalnya berupa
              kamar-kamar yang tertutup dengan sekat permanen atau kamar tunggal
              dengan daun pintu yang dapat ditutup dan dikunci)
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[3].status == 1"
                  @click="dataConfirm.sanitasi[3].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[3].status == 0"
                  @click="dataConfirm.sanitasi[3].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  sanitasi
                    .Toilet_dipisahkan_dengan_jelas_untuk_pria_dan_wanita_atau_memberikan_privasi__misal__kamar_kamar_tunggal__jika_netral_gender[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              9. Apakah toilet wanita memiliki fasilitas untuk manajemen
              kebersihan menstruasi (MKM)? (MKM dapat berupa pembalut, tempat
              sampah tertutup, serta air dan sabun)
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[4].status == 1"
                  @click="dataConfirm.sanitasi[4].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[4].status == 0"
                  @click="dataConfirm.sanitasi[4].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  sanitasi[
                    'Toilet_wanita_memiliki_fasilitas_untuk_manajemen_kebutuhan_menstruasi__tempat_sampah_tertutup,_dan_atau_air_dan_sabun_'
                  ][0].dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              10. Apakah tersedia setidaknya satu toilet yang dapat diakses oleh
              orang dengan mobilitas terbatas? (toilet dengan kloset duduk,
              pintu yang lebar dan lantai yang rata sehingga dapat dilalui kursi
              roda, serta dilengkapi dengan pegangan tangan)
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[5].status == 1"
                  @click="dataConfirm.sanitasi[5].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[5].status == 0"
                  @click="dataConfirm.sanitasi[5].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  sanitasi
                    .Setidaknya_satu_toilet_dapat_diakses_oleh_orang_orang_dengan_mobilitas_terbatas[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              11. Apakah Fasyankes memiliki jumlah toilet yang cukup untuk
              digunakan oleh petugas, pasien, dan pengunjung? (jumlah toilet
              yang cukup dapat diperkirakan dari tidak adanya antrean)
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[6].status == 1"
                  @click="dataConfirm.sanitasi[6].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.sanitasi[6].status == 0"
                  @click="dataConfirm.sanitasi[6].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  sanitasi
                    .Fasilitas_memiliki_jumlah_toilet_yang_cukup_dan_dapat_digunakan_untuk_pasien[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card color="#CFEEF2" flat class="rounded-max mb-5">
            <div class="fill_header3 white--text py-6 px-5">
              <b>Limbah</b>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              12. Apakah limbah benda tajam, infeksius, dan domestik dipisahkan
              dengan aman di dalam tiga wadah berbeda di ruang konsultasi atau
              perawatan?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.higiene[0].status == 1"
                  @click="dataConfirm.higiene[0].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.higiene[0].status == 0"
                  @click="dataConfirm.higiene[0].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  limbah
                    .Limbah_benda_tajam_infeksius_dan_domestik_dipisahkan_dengan_aman_menjadi_3_wadah_di_ruang_konsultasi[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Ya, di sebagian ruangan"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              13. Bagaimana cara pengolahan atau pembuangan limbah benda tajam?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.higiene[1].status == 1"
                  @click="dataConfirm.higiene[1].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.higiene[1].status == 0"
                  @click="dataConfirm.higiene[1].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  limbah.pengolahan_pembuangan_limbah_benda_tajam[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col cols="4">
                    <v-radio
                      label="Diolah dengan autoklaf atau microwave berizin"
                      color="mainGreen"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Diolah dengan insinerator berizin"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Diolah bekerja sama dengan pengolah berizin"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                    <v-radio
                      label="Dikubur di dalam lubang yang dilindungi dan dilapisi (enkapsulasi)"
                      color="mainGreen"
                      value="4"
                    ></v-radio>
                  </v-col>
                  <v-col cols="4">
                    <v-radio
                      label="Diolah dengan insinerator tidak berizin"
                      color="mainGreen"
                      value="5"
                    ></v-radio>
                    <v-radio
                      label="Dilakukan pembakaran di lubang terlindungi"
                      color="mainGreen"
                      value="6"
                    ></v-radio>
                    <v-radio
                      label="Dilakukan pembuangan terbuka tanpa diolah"
                      color="mainGreen"
                      value="7"
                    ></v-radio>
                  </v-col>
                  <v-col cols="4">
                    <v-radio
                      label="Dilakukan pembakaran terbuka"
                      color="mainGreen"
                      value="8"
                    ></v-radio>
                    <v-radio
                      label="Disimpan dan tidak diolah"
                      color="mainGreen"
                      value="9"
                    ></v-radio>
                    <v-radio
                      label="Dibuang bersama limbah domestik"
                      color="mainGreen"
                      value="10"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              14. Bagaimana cara pengolahan atau pembuangan limbah infeksius?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.higiene[2].status == 1"
                  @click="dataConfirm.higiene[2].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.higiene[2].status == 0"
                  @click="dataConfirm.higiene[2].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  limbah.pengolahan_pembuangan_limbah_infeksius[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col cols="4">
                    <v-radio
                      label="Diolah dengan autoklaf atau microwave berizin"
                      color="mainGreen"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Diolah dengan insinerator berizin"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Diolah bekerja sama dengan pengolah berizin"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                    <v-radio
                      label="Dikubur di dalam lubang yang dilindungi dan dilapisi (sanitary landfill)"
                      color="mainGreen"
                      value="4"
                    ></v-radio>
                  </v-col>
                  <v-col cols="4">
                    <v-radio
                      label="Diolah dengan insinerator tidak berizin"
                      color="mainGreen"
                      value="5"
                    ></v-radio>
                    <v-radio
                      label="Dilakukan pembakaran di lubang terlindungi"
                      color="mainGreen"
                      value="6"
                    ></v-radio>
                    <v-radio
                      label="Dilakukan pembuangan terbuka tanpa diolah"
                      color="mainGreen"
                      value="7"
                    ></v-radio>
                  </v-col>
                  <v-col cols="4">
                    <v-radio
                      label="Dilakukan pembakaran terbuka"
                      color="mainGreen"
                      value="8"
                    ></v-radio>
                    <v-radio
                      label="Disimpan dan tidak diolah"
                      color="mainGreen"
                      value="9"
                    ></v-radio>
                    <v-radio
                      label="Dibuang bersama limbah domestik"
                      color="mainGreen"
                      value="10"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              15. Apakah pengurangan limbah medis dan/atau domestik yang terdiri
              dari prevention (pencegahan timbulan limbah), reduce (pengurangan
              limbah), reuse (guna ulang), dan recycle (daur ulang) telah
              dilakukan?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.limbah[0].status == 1"
                  @click="dataConfirm.limbah[0].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.limbah[0].status == 0"
                  @click="dataConfirm.limbah[0].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  limbah[
                    'pengurangan_limbah_medis,_domestik_terdiri_dari_prevention_pencegahan__timbulan_limbah_reduce_pengurangan_limbah_reuse_gunakan_kembali_recyle_daur_ulang_telah_dilakukan'
                  ][0].dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio
                      label="Ya dan sudah menjadi ketetapan"
                      color="mainGreen"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Ya tetapi pelaksanaannya tidak konsisten"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Tidak pernah dilakukan"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>

          <v-card color="#CFEEF2" flat class="rounded-max mb-5">
            <div class="fill_header4 white--text py-6 px-5">
              <b>Higiene</b>
            </div>
          </v-card>

          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              16. Apakah air dan sabun (sarana cuci tangan) atau antiseptik
              berbasis alkohol tersedia di ruang konsultasi atau perawatan?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.limbah[1].status == 1"
                  @click="dataConfirm.limbah[1].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.limbah[1].status == 0"
                  @click="dataConfirm.limbah[1].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  higiene
                    .Sabun_dan_air_atau_antiseptik_berbasis_alkohol_tersedia_diruang_konsultasi[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Ya, tersedia di sebagian ruangan"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              17. Apakah air dan sabun (sarana cuci tangan) tersedia di toilet?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.limbah[2].status == 1"
                  @click="dataConfirm.limbah[2].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.limbah[2].status == 0"
                  @click="dataConfirm.limbah[2].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  higiene.Sabun_dan_air_tersedia_di_toilet[0].dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio
                      label="Ya dengan jarak kurang dari 5 meter"
                      color="mainGreen"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Ya dengan jarak lebih dari 5 meter"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              18. Apakah pemantauan kepatuhan kebersihan tangan dilakukan dengan
              pengamatan langsung pada lima momen mencuci tangan? (lima momen
              cuci tangan terdiri dari: sebelum kontak dengan pasien dan
              tindakan aseptik serta setelah terkena cairan tubuh pasien, kontak
              dengan pasien, dan kontak dengan lingkungan di sekitar pasien)
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.limbah[3].status == 1"
                  @click="dataConfirm.limbah[3].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.limbah[3].status == 0"
                  @click="dataConfirm.limbah[3].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  higiene
                    .Pemantauan_Kepatuhan_kebersihan_tangan_dilakukan_dengan_pengamatan_langsung_dilihat_dari_5_momen_mencuci_tangan[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>

          <v-card color="#CFEEF2" flat class="rounded-max mb-5">
            <div class="fill_header5 white--text py-6 px-5">
              <b>Kebersihan Lingkungan</b>
            </div>
          </v-card>

          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              19. Apakah prosedur untuk pembersihan ruangan, toilet, dan
              tumpahan darah atau cairan tubuh dan jadwal pembersihannya
              tersedia?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.kesling[0].status == 1"
                  @click="dataConfirm.kesling[0].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.kesling[0].status == 0"
                  @click="dataConfirm.kesling[0].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  lingkungan
                    .Protokol_untuk_pembersihan_lantai_wastafel_tumpahan_darah_atau_cairan_tubuh_dan_jadwal_pemberseihan_tersedia[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card class="rounded-max py-3 px-5 mb-5" outlined>
            <div class="d-flex justify-space-between">
              <h5>
              20. Apakah semua petugas kebersihan telah menerima pelatihan atau
              materi terkait kebersihan?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.kesling[1].status == 1"
                  @click="dataConfirm.kesling[1].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.kesling[1].status == 0"
                  @click="dataConfirm.kesling[1].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  lingkungan
                    .Semua_staf_bertanggung_jawab_untuk_pembersihan_telah_menerima_pelatihan[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Ya, sebagian"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
          <v-card
            class="rounded-max py-3 px-5 mb-5"
            outlined
            v-if="
              lingkungan.Catatan_pemantauan_kebersihan_tersedia_untuk_area_perawatan_pasien___bangsal_umum___fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari_di_setiap_area___bangsal___seluruh_fasilitas
            "
          >
            <div class="d-flex justify-space-between">
              <h5>
              21. Apakah catatan pemantauan kebersihan tersedia untuk area
              perawatan, bangsal umum, toilet, dan fasilitas lainnya serta
              ditandatangani oleh petugas kebersihan yang relevan setiap hari di
              setiap area?
            </h5>
              <div v-if="dataConfirm">
                <v-btn
                  depressed
                  v-if="dataConfirm.kesling[2].status == 1"
                  @click="dataConfirm.kesling[2].status = 0"
                  color="grey lighten-2"
                  dark
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
                <v-btn
                  depressed
                  v-if="dataConfirm.kesling[2].status == 0"
                  @click="dataConfirm.kesling[2].status = 1"
                  color="primary"
                >
                  <v-icon>mdi-close-box</v-icon> Tidak Sesuai
                </v-btn>
              </div>
            </div>
            <div style="margin: 0 20px">
              <v-radio-group
                disabled
                v-model="
                  lingkungan
                    .Catatan_pemantauan_kebersihan_tersedia_untuk_area_perawatan_pasien___bangsal_umum___fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari_di_setiap_area___bangsal___seluruh_fasilitas[0]
                    .dataPenyusun_desc
                "
                dense
              >
                <v-row>
                  <v-col>
                    <v-radio label="Ya" color="mainGreen" value="1"></v-radio>
                    <v-radio
                      label="Ya, sebagian"
                      color="mainGreen"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="Tidak"
                      color="mainGreen"
                      value="3"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-card>
        </v-card>
        <v-card class="rounded-max custom_card pa-5 mt-5" v-if="dataConfirm">
          <v-textarea
            background-color="#F2F2F2"
            flat
            placeholder="Catatan"
            solo
            v-model="dataConfirm.notes"
          ></v-textarea>
          <div class="d-flex justify-center">
            <v-btn
              dark
              color="#00b4cc"
              class="pa-6 text-capitalize rounded-lg"
              depressed
              width="230px"
              @click="dialogAlertConfirm = true"
            >
              <b>Kirim</b> <v-icon class="ml-2">mdi-tray-arrow-up</v-icon>
            </v-btn>
          </div>
        </v-card>
        <!-- <div class="d-flex justify-center mt-5">
          <v-btn
            dark
            color="#00b4cc"
            class="pa-6 text-capitalize rounded-lg"
            depressed
            width="230px"
            @click="setupForm(false)"
          >
            <b>Kirim</b> <v-icon class="ml-2">mdi-tray-arrow-up</v-icon>
          </v-btn>
        </div> -->
        <alert-confirm
          :dialogAlert="dialogAlertConfirm"
          @pick="sentConfirm"
          @close="dialogAlertConfirm = false"
        />
        <Alert
          v-bind:dialogAlert="dialogAlert"
          v-bind:temporary="temporary"
          v-bind:body="tobeSent"
          @close="dialogAlert = false"
          @save="savePermanent()"
        />
        <SuccessAlert v-bind:dialogSuccess="dialogSuccess" />
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="#00b4cc"
          ></v-progress-circular>
          <b class="color_default">Loading...</b>
        </v-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderFormulir from "../../../components/Formulir/HeaderFormulir.vue";
import Alert from "../../../components/Formulir/Modal/alert.vue";
import SuccessAlert from "../../../components/Formulir/Modal/successAlert.vue";
import AlertConfirm from "./alertConfirm.vue";
export default {
  name: "PertanyaanKunci",
  computed: {
    ...mapState({
      keyQuestion: (state) => state.formulir.pertanyaan_kunci,
    }),
    air() {
      let data = null;
      if (this.keyQuestion) {
        data = this.keyQuestion.Kesehatan_Lingkungan.Air.Air;
      }
      return data;
    },
    sanitasi() {
      let data = null;
      if (this.keyQuestion) {
        data = this.keyQuestion.Kesehatan_Lingkungan.Toilet.Sanitasi;
      }
      return data;
    },
    higiene() {
      let data = null;
      if (this.keyQuestion) {
        data =
          this.keyQuestion.Kesehatan_Lingkungan.Manajemen_dan_Tenaga_Kerja
            .Higiene;
      }
      return data;
    },
    limbah() {
      let data = null;
      if (this.keyQuestion) {
        data =
          this.keyQuestion.Kesehatan_Lingkungan.Pengelolaan_Limbah_Medis.Limbah;
      }
      return data;
    },
    lingkungan() {
      let data = null;
      if (this.keyQuestion) {
        data =
          this.keyQuestion.Kesehatan_Lingkungan.Kebersihan_Lingkungan
            .Kebersihan_Lingkungan;
      }
      return data;
    },
  },
  components: { HeaderFormulir, Alert, SuccessAlert, AlertConfirm },
  data() {
    return {
      id: null,
      date: null,
      dialogSuccess: false,
      dialogAlert: false,
      dialogAlertConfirm: false,
      temporary: true,
      loading: false,
      loadTempo: false,
      date: null,
      tobeSent: null,
      dataConfirm: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getData();
    this.getDataConfrim();
  },
  methods: {
    getData() {
      this.loading = true;
      let body = {
        report_id: this.id,
      };
      console.log(body);
      this.$store.dispatch("formulir/getPertanyaanKunci", body).then((data) => {
        this.date = data.data.date;
        this.loading = false;
      });
    },
    getDataConfrim() {
      this.loading = true;
      this.$store
        .dispatch("formulir/getDetailConfirmKesling", this.id)
        .then((data) => {
          let obj = {};
          data.wasteData.forEach((el) => {
            if (el.wasteData_name == "Air") {
              obj["air"] = el.verification_data_penyusun;
              if (data.verification_status == "0" || data.verification_status == "1") {
                obj.air.forEach((i) => {
                  i.status = 1;
                });
              }
            } else if (el.wasteData_name == "Sanitasi") {
              obj["sanitasi"] = el.verification_data_penyusun;
              if (data.verification_status == "0" || data.verification_status == "1") {
                obj.sanitasi.forEach((i) => {
                  i.status = 1;
                });
              }
            } else if (el.wasteData_name == "Higiene") {
              obj["higiene"] = el.verification_data_penyusun;
              if (data.verification_status == "0" || data.verification_status == "1") {
                obj.higiene.forEach((i) => {
                  i.status = 1;
                });
              }
            } else if (el.wasteData_name == "Limbah") {
              obj["limbah"] = el.verification_data_penyusun;
              if (data.verification_status == "0" || data.verification_status == "1") {
                obj.limbah.forEach((i) => {
                  i.status = 1;
                });
              }
            } else if (el.wasteData_name == "Kebersihan Lingkungan") {
              obj["kesling"] = el.verification_data_penyusun;
              if (data.verification_status == "0" || data.verification_status == "1") {
                obj.kesling.forEach((i) => {
                  i.status = 1;
                });
              }
            }
          });
          obj["notes"] = data.notes;
          obj["id"] = data.verification_id;
          this.dataConfirm = obj;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    sentConfirm(verif_status) {
      this.loading = true;
      let vm = this;
      let data = {
        report_id: this.id,
        verification_id: this.dataConfirm.id,
        verification_status: verif_status,
        notes: this.dataConfirm.notes,
        data: [
          // AIR => => => => => => => => => => => => => => => => => => => => => => => =>
          {
            status: vm.dataConfirm.air[0].status,
            dataPenyusun_id: vm.dataConfirm.air[0].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.air[0].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.air[1].status,
            dataPenyusun_id: vm.dataConfirm.air[1].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.air[1].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.air[2].status,
            dataPenyusun_id: vm.dataConfirm.air[2].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.air[2].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.air[3].status,
            dataPenyusun_id: vm.dataConfirm.air[3].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.air[3].verificationDataPenyusun_id,
          },
          // SANITASI => => => => => => => => => => => => => => => => => => => => => => => =>
          {
            status: vm.dataConfirm.sanitasi[0].status,
            dataPenyusun_id: vm.dataConfirm.sanitasi[0].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.sanitasi[0].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.sanitasi[1].status,
            dataPenyusun_id: vm.dataConfirm.sanitasi[1].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.sanitasi[1].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.sanitasi[2].status,
            dataPenyusun_id: vm.dataConfirm.sanitasi[2].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.sanitasi[2].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.sanitasi[3].status,
            dataPenyusun_id: vm.dataConfirm.sanitasi[3].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.sanitasi[3].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.sanitasi[4].status,
            dataPenyusun_id: vm.dataConfirm.sanitasi[4].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.sanitasi[4].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.sanitasi[5].status,
            dataPenyusun_id: vm.dataConfirm.sanitasi[5].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.sanitasi[5].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.sanitasi[6].status,
            dataPenyusun_id: vm.dataConfirm.sanitasi[6].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.sanitasi[6].verificationDataPenyusun_id,
          },
          // HIGIENE => => => => => => => => => => => => => => => => => => => => => => => =>
          {
            status: vm.dataConfirm.higiene[0].status,
            dataPenyusun_id: vm.dataConfirm.higiene[0].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.higiene[0].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.higiene[1].status,
            dataPenyusun_id: vm.dataConfirm.higiene[1].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.higiene[1].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.higiene[2].status,
            dataPenyusun_id: vm.dataConfirm.higiene[2].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.higiene[2].verificationDataPenyusun_id,
          },
          // LIMBAH => => => => => => => => => => => => => => => => => => => => => => => =>
          {
            status: vm.dataConfirm.limbah[0].status,
            dataPenyusun_id: vm.dataConfirm.limbah[0].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.limbah[0].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.limbah[1].status,
            dataPenyusun_id: vm.dataConfirm.limbah[1].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.limbah[1].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.limbah[2].status,
            dataPenyusun_id: vm.dataConfirm.limbah[2].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.limbah[2].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.limbah[3].status,
            dataPenyusun_id: vm.dataConfirm.limbah[3].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.limbah[3].verificationDataPenyusun_id,
          },
          // KESLING => => => => => => => => => => => => => => => => => => => => => => => =>
          {
            status: vm.dataConfirm.kesling[0].status,
            dataPenyusun_id: vm.dataConfirm.kesling[0].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.kesling[0].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.kesling[1].status,
            dataPenyusun_id: vm.dataConfirm.kesling[1].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.kesling[1].verificationDataPenyusun_id,
          },
          {
            status: vm.dataConfirm.kesling[2].status,
            dataPenyusun_id: vm.dataConfirm.kesling[2].dataPenyusun_id,
            verificationDataPenyusun_id:
              vm.dataConfirm.kesling[2].verificationDataPenyusun_id,
          },
        ],
      };
      this.$store.dispatch("formulir/confirmKesling", data).then((data) => {
        console.log(data);
        this.loading = false;
        this.$router.push("/konfirmasi/formulir");
      });
    },
  },
};
</script>

<style scoped>
.fill_header1 {
  background: #5DBCDF;
  width: 100%;
}
.fill_header2 {
  background: #79BF7C;
  width: 100%;
}
.fill_header3 {
  background: #783882;
  width: 100%;
}
.fill_header4 {
  background: #D33937;
  width: 100%;
}
.fill_header5 {
  background: #D8127E;
  width: 100%;
}
.fill_header {
  background: #00b4cc;
  width: 30%;
}
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
</style>